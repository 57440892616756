/* eslint-disable no-restricted-syntax */
<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-row
      v-if="auditLogData"
      class="print-container"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
      >
        <b-card>
          <section class="invoice-preview-wrapper">

            <b-row>
              <b-col
                lg="4"
                sm="6"
              >
                <statistic-card-horizontal
                  icon="ServerIcon"
                  color="primary"
                  :statistic="auditLogData.table_name"
                  statistic-title="Resource"
                />
              </b-col>
              <b-col
                lg="8"
                sm="6"
              >
                <statistic-card-horizontal
                  icon="ClockIcon"
                  color="primary"
                  :statistic="auditLogData.dml_created_at"
                  statistic-title="Time"
                />
              </b-col>
              <b-col
                lg="4"
                sm="6"
              >
                <statistic-card-horizontal
                  icon="KeyIcon"
                  color="primary"
                  :statistic="auditLogData.record_id"
                  statistic-title="Record ID"
                />
              </b-col>
              <b-col
                lg="4"
                sm="6"
              >
                <statistic-card-horizontal
                  icon="FlagIcon"
                  color="primary"
                  :statistic="auditLogData.dml_action"
                  statistic-title="Action"
                />
              </b-col>

              <b-col
                lg="4"
                sm="6"
              >
                <statistic-card-horizontal
                  icon="UserIcon"
                  color="primary"
                  :statistic="auditLogData.dml_created_by"
                  statistic-title="User"
                />
              </b-col>
            </b-row>
            <b-row
              class="invoice-preview"
            >
              <b-col
                cols="12"
                xl="6"
                md="6"
              >
                <div
                  class="invoice-preview-card"
                >
                  <div class="mt-1">

                    <!-- Invoice Description: Table -->
                    <!-- <b-table-lite
                      class="mt-1"
                      hover
                      bordered
                      responsive
                      :items="oldData"
                      :fields="[{ label: 'Field', key: 'k'}, { label: 'Old Data', key: 'v'}]"
                    /> -->

                    <b-table-simple
                      hover
                      bordered
                      responsive
                      small
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="2">
                            Old Data
                          </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="item in oldData"
                          :key="item.k"
                        >
                          <b-td>{{ item.k }}</b-td>
                          <b-td class="text-dark">
                            <span v-html="(Array.isArray(item.v)) ? toTable(item.v) : item.v" />
                          </b-td>
                        </b-tr>
                      </b-tbody>

                    </b-table-simple>

                  </div>

                </div>
              </b-col>

              <b-col
                cols="12"
                xl="6"
                md="6"
              >
                <div
                  class="invoice-preview-card"
                >
                  <div class="mt-1">

                    <!-- Invoice Description: Table -->
                    <!-- <b-table-lite
                      class="mt-1"
                      hover
                      bordered
                      responsive
                      :items="newData"
                      :fields="[{ label: 'Field', key: 'k'}, { label: 'New Data', key: 'v'}]"
                    /> -->

                    <!-- Invoice Description: Table -->
                    <b-table-simple
                      hover
                      bordered
                      responsive
                      small
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="2">
                            New Data
                          </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="item in newData"
                          :key="item.k"
                        >
                          <b-td>{{ item.k }}</b-td>
                          <b-td class="text-dark">
                            <span v-html="(Array.isArray(item.v)) ? toTable(item.v) : item.v" />
                          </b-td>
                        </b-tr>
                      </b-tbody>

                    </b-table-simple>

                  </div>

                </div>
              </b-col>

            </b-row>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="button"
                @click="printAuditLog"
              >
                <feather-icon icon="PrinterIcon" />&nbsp;
                Print
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="close"
              >
                <feather-icon icon="StopCircleIcon" />&nbsp;
                Close
              </b-button>
            </div>

          </section>

        </b-card>

      </b-col>

    </b-row>

  </b-overlay>

</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BButton, BTableSimple, BThead, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import router from '@/router/index'
import store from '@/store'
import StatisticCardHorizontal from '../../../common/StatisticCardHorizontal.vue'
import storeModule from '../../../common/storeModule'
import auditLogStoreModule from '../auditLogStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BButton,
    BRow,
    BCol,
    BTableSimple,
    StatisticCardHorizontal,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    close() {
      this.$router.push({ name: 'audit-log-list' })
    },
    toTable(arr) {
      let tableTags = `
      `
      for (let i = 0; i < arr.length; i += 1) {
        tableTags += `
        <table class="mb-1 mt-1 table b-table table-sm">
          <tbody>
        `
        /* eslint no-restricted-syntax: ["error", "WithStatement", "BinaryExpression[operator='in']"] */
        // eslint-disable-next-line guard-for-in
        for (const prop in arr[i]) {
          tableTags += `
            <tr>
              <td>${prop}</td>
              <td class="text-dark">${arr[i][prop]}</td>
            </tr>
          `
        }

        tableTags += `
          </tbody>
        </table>`
      }

      return tableTags
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'audit-log'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, auditLogStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const auditLogData = ref({
      table_name: '',
      record_id: null,
      dml_action: '',
      old_row_data: null,
      new_row_data: null,
      dml_created_at: [],
      dml_created_by: '',
    })

    const auditLogId = router.currentRoute.params.id

    let dataArray = []
    const oldData = ref([])
    const newData = ref([])

    store.dispatch('audit-log/fetchOne', { id: auditLogId })
      .then(response => {
        const responseData = {
          id: response.data.data.id,
          table_name: response.data.data.table_name,
          record_id: response.data.data.record_id,
          dml_action: response.data.data.dml_action,
          old_row_data: JSON.parse(response.data.data.old_row_data),
          new_row_data: JSON.parse(response.data.data.new_row_data),
          dml_created_at: response.data.data.dml_created_at,
          dml_created_by: response.data.data.dml_created_by,
        }

        try {
          const od = JSON.parse(response.data.data.old_row_data)
          const nd = JSON.parse(response.data.data.new_row_data)
          /* eslint no-restricted-syntax: ["error", "WithStatement", "BinaryExpression[operator='in']"] */
          // eslint-disable-next-line guard-for-in
          for (const prop in od) {
            dataArray.push({ k: prop, v: od[prop] })
          }
          oldData.value = dataArray
          dataArray = []
          /* eslint no-restricted-syntax: ["error", "WithStatement", "BinaryExpression[operator='in']"] */
          // eslint-disable-next-line guard-for-in
          for (const prop in nd) {
            dataArray.push({ k: prop, v: nd[prop] })
          }
          newData.value = dataArray
        } catch (ex) {
          console.log(ex)
        }

        auditLogData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          auditLogData.value = undefined
        }
      })

    const printAuditLog = () => {
      window.print()
    }

    return {
      auditLogData,
      printAuditLog,
      oldData,
      newData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.text-dark {
  color: #000000;
}
.w-120px {
  width: 120px;
}
.pr-2rem {
  padding-right: 2rem !important;
}
.tabStyle {
  color : black !important;
}
[dir] .nav-tabs .nav-link {
  padding-left: 0;
}
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  .print-container {
    > [class*="col-"] {
      flex: 0 0 100%;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  [dir] .card {
    border: none !important;
  }
  [dir] .card-body {
    padding: 0;
  }
  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
